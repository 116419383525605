<template>
  <div class="product-wrap programme-water">
    <Header is-active="programme" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-water">
      <!--      <div class="txt-wrap">-->
      <!--        <div class="txt-box">-->
      <!--          <h1>智慧水务解决方案</h1>-->
      <!--          <p>深耕水务行业多年</p>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="white-wrap">
      <div class="txt-box align-ct no-indent pd-5">
        <p>智慧水务基于先进的物联网技术、云计算大数据中心，依托涉水领域共享信息，构建基于数据中心的应用系统，</p>
        <p>为涉水的政务业务、社会服务等领域提供智能化可靠解决方案，</p>
        <p>以更加灵活高效、安全实时的方式支持水务运行的高复杂度系统工程。</p>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="fz-big align-ct"><h4>行业痛点</h4></div>
      <div class="pain-box">
        <div class="td-item">
          <img src="../../assets/img/programme/water/td_a.jpg" alt="统一管理难度大">
          <p class="color-59">统一管理难度大，厂区分布广泛，仪表设备数量，距离远。</p>
        </div>
        <div class="td-item">
          <img src="../../assets/img/programme/water/td_b.jpg" alt="颗粒度不够">
          <p class="color-59">颗粒度不够，很难准确精确提出绩效指标，监控数据不足。</p>
        </div>
        <div class="td-item">
          <img src="../../assets/img/programme/water/td_c.jpg" alt="成本控制难">
          <p class="color-59">成本控制难，跑冒滴漏，空载运行，高耗能点位监管缺失。</p>
        </div>
        <div class="td-item">
          <img src="../../assets/img/programme/water/td_d.jpg" alt="数据价值丢失">
          <p class="color-59">数据价值丢失，数字化技术和业务融合不足，数据挖掘少。</p>
        </div>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <div class="fz-big align-ct"><h4>云昇方案</h4></div>
        <div class="txt-box align-lt" style="padding-bottom: 1%">
          结合水厂的日常巡检、PLC自动化设备控制、药剂管控、生产设备周期性维护、设备巡检、交接班、学习培训等生产管理工作，实现水厂生产过程管理的标准化和规范化，实现水厂业务全方位管控、信息汇集、资源共享、优化管理。确保水质安全、提升管理效率等方面的保障作用。
        </div>
        <div class="auto-img">
          <img class="mt-2" src="../../assets/img/programme/water/pic_a.png" alt="云昇方案">
        </div>
      </div>
      <div class="grey-wrap">
        <div class="max-center">
          <div class="fz-big align-ct"><h4>方案场景</h4></div>
          <div class="auto-img">
            <img class="mt-2" src="../../assets/img/programme/water/pic_b.png" alt="方案场景">
          </div>
        </div>
      </div>
      <div class="white-wrap">
        <div class="max-center">
          <div class="fz-big align-ct"><h4>业务功能</h4></div>
          <div class="auto-img">
            <img class="mt-2" src="../../assets/img/product/water/jg.png" alt="业务功能">
          </div>
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="fz-big align-ct"><h4>方案价值</h4></div>
        <div class="auto-img">
          <img class="mt-2" src="../../assets/img/programme/water/pic_c.png" alt="方案价值">
        </div>
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <div class="fz-big align-ct"><h4>方案优势</h4></div>
        <div class="txt-box align-ct" style="padding-bottom: 1%">
          云昇基于对客户需求的深入洞察，从调研规划、产品组合到交付运维，全面地为水务企业智慧水厂建设、运营、管理提供精准的解决方案，助力打造安全、高效、精益的智慧水厂，实现设计制造一体化、管控一体化，提高制水品质和管理效率。在此基础上，智慧水厂以安全可靠、节能降耗、智慧管理为建设目标，最终为百姓提供合格健康的水。
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center">
        <div class="fz-big align-ct"><h4>实际案例</h4></div>
        <el-row gutter="10">
          <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10" style="margin-bottom: 6%">
            <div class="case-box">
              <div class="case-item left-pic" @click="handleGo('/case-b')">
                <div class="txt-bg">某水务集团智慧水务平台项目</div>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
            <div class="case-box">
              <div class="big-pic case-item" @click="handleGo('/case-a')">
                <div class="txt-bg">某污水处理项目</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <Footer></Footer>
    <BackTop></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '智慧农业-方案中心,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控智慧农业-方案中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {
    handleGo (way) {
      this.$router.push({ path: way })
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.programme-water {
  .banner-water {
    background: url("../../assets/img/programme/water/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;

    //.txt-wrap {
    //  width: 50%;
    //  height: 34%;
    //  background-color: rgba(24, 114, 255, .8);
    //  position: absolute;
    //  left: 25%;
    //  top: 25%;
    //
    //  .txt-box {
    //    width: 98%;
    //    height: 90%;
    //    margin: 1% auto;
    //    text-align: center;
    //    font-family: Microsoft YaHei;
    //    border: 1px solid #ffffff;
    //    color: #FFFFFF;
    //
    //    h1 {
    //      font-size: 60px;
    //      font-weight: bold;
    //      line-height: 1.8;
    //    }
    //
    //    p {
    //      font-size: 30px;
    //      font-weight: 400;
    //      line-height: 1;
    //    }
    //  }
    //}

  }

  .case-box {
    width: 100%;
    height: 520px;

    .half-pic {
      width: 96%;
      margin: 0 auto;
      height: 47%;
      position: relative;
      background: url("../../assets/img/programme/water/case_a.jpg") no-repeat center;
      background-size: cover;
    }

    .ca-down {
      background: url("../../assets/img/programme/water/case_b.jpg") no-repeat center;
      background-size: cover;
    }

    .left-pic {
      width: 96%;
      margin: 0 auto;
      height: 100%;
      position: relative;
      background: url("../../assets/img/programme/water/case_b.jpg") no-repeat center;
      background-size: cover;
    }

    .big-pic {
      width: 96%;
      margin: 0 auto;
      height: 100%;
      position: relative;
      background: url("../../assets/img/programme/water/case_c.jpg") no-repeat center;
      background-size: cover;
    }

    .txt-bg {
      display: none;
      position: absolute;
      height: 45px;
      background-color: rgba(0, 0, 0, .5);
      bottom: 0;
      left: 0;
      right: 0;
      color: #ffffff;
      text-align: left;
      line-height: 45px;
      text-indent: 1em;
    }

    .case-item:hover {
      .txt-bg {
        display: block;
      }
    }
  }
}
</style>
